export const PRICES_LIST = [
    // {
    //     title: "Fall/Winter Single Lesson",
    //     cost: "19",
    //     middle: false,
    //     bumpUp: true,
    //     showButton: true,
    //     cardSize: 4,
    //     button: {
    //         link: "https://swim-academy.herokuapp.com/our_lessons",
    //         title: "Buy A Lesson",
    //         color: "info"
    //     },
    //     list: [
    //         {
    //             bold: "1",
    //             title: "Private 20 minute Lesson"
    //         },
    //         {
    //             bold: "Purchase",
    //             title: "Aug 15th - Feb 15th"
    //         },
    //         {
    //             bold: "Expires",
    //             title: "Within 3 months of Purchase"
    //         },
    //         {
    //             bold: "24",
    //             title: "Hours Advanced Cancellation"
    //         },
    //     ]
    // },
    // {
    //     title: "Fall/Winter 10 Pack Lessons",
    //     cost: "180",
    //     middle: true,
    //     bumpUp: true,
    //     showButton: true,
    //     cardSize: 4,
    //     button: {
    //         link: "https://swim-academy.herokuapp.com/our_lessons",
    //         title: "Buy Lessons",
    //         color: "white"
    //     },
    //     list: [
    //         {
    //             bold: "10",
    //             title: "Private 20 minute Lessons"
    //         },
    //         {
    //             bold: "Purchase",
    //             title: "Aug 15th - Feb 15th"
    //         },
    //         {
    //             bold: "Expires",
    //             title: "Within 3 months of Purchase"
    //         },
    //         {
    //             bold: "24",
    //             title: "Hours Advanced Cancellation"
    //         },
    //     ]
    // },
    // {
    //     title: "Fall/Winter 20 Pack Lessons",
    //     cost: "340",
    //     middle: false,
    //     bumpUp: true,
    //     showButton: true,
    //     cardSize: 4,
    //     button: {
    //         link: "https://swim-academy.herokuapp.com/our_lessons",
    //         title: "Buy Lessons",
    //         color: "info"
    //     },
    //     list: [
    //         {
    //             bold: "20",
    //             title: "Private 20 minute Lessons"
    //         },
    //         {
    //             bold: "Purchase",
    //             title: "Aug 15th - Feb 15th"
    //         },
    //         {
    //             bold: "Expires",
    //             title: "Within 3 months of Purchase"
    //         },
    //         {
    //             bold: "24",
    //             title: "Hours Advanced Cancellation"
    //         },
    //     ]
    // },
    {
        title: "Spring/Summer Single Lesson",
        cost: "25.99",
        middle: false,
        bumpUp: true,
        showButton: true,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "1",
                title: "Private 20 minute Lesson"
            },
            {
                bold: "Purchase",
                title: "Feb 16th - Aug 14th"
            },
            {
                bold: "Expires",
                title: "Within 6 months of Purchase"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    {
        title: "Spring/Summer 10 Pack Lessons",
        cost: "240",
        middle: true,
        bumpUp: true,
        showButton: true,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "10",
                title: "Private 20 minute Lessons"
            },
            {
                bold: "Purchase",
                title: "Feb 16th - Aug 14th"
            },
            {
                bold: "Expires",
                title: "Within 6 months of Purchase"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    {
        title: "Spring/Summer 30 Pack Lessons",
        cost: "575",
        middle: false,
        bumpUp: true,
        showButton: true,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "30",
                title: "Private 20 minute Lessons"
            },
            {
                bold: "Purchase",
                title: "Feb 16th - Aug 14th"
            },
            {
                bold: "Expires",
                title: "Within 6 months of Purchase"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    {
        title: "Cancellation Insurance",
        cost: "25",
        middle: true,
        showButton: false,
        cardSize: 12,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "Lesson cancellation insurance can be purchased for $25 per month."
            },
            {
                bold: "Allows Cancellation: ",
                title: "This allows you to cancel up to 5 minutes before your lesson time!"
            },
            {
                bold: "How to Purchase: ",
                title: "To purchase, DM us or text our business line at 801.367.0648"
            }
        ]
    },
    {
        title: "Lesson Extensions for 1 Lesson",
        cost: "5",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 3,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "1",
                title: "Lesson Extension for 1 month"
            }
        ]
    },
    {
        title: "Lesson Extensions for 10 Lessons",
        cost: "15",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 3,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "10",
                title: "Lessons Extension for 1 month"
            }
        ]
    },
    {
        title: "Lesson Extensions for 20 Lessons",
        cost: "30",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 3,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "20",
                title: "Lessons Extension for 1 month"
            }
        ]
    },
    {
        title: "Lesson Extensions for 30 Lessons",
        cost: "45",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 3,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "30",
                title: "Lessons Extension for 1 month"
            }
        ]
    },
    {
        title: "Swim Team (Per Practice Plan) Fall/Winter/Spring",
        cost: "15",
        middle: true,
        bumpUp: false,
        showButton: false,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Practice: ",
                title: "Pay $15 per practice"
            },
            {
                bold: "Requirements: ",
                title: "Must be able to swim front stroke and back stroke at least one length of the pool, unassisted. Must be between 5-13 years of age."
            },
            {
                bold: "Expectations: ",
                title: "This is an introductory swim team! We work on strokes and techniques and prepare the kids for more advanced swim teams!"
            },
            {
                bold: "When: ",
                title: "Saturday mornings for one hour."
            },
            {
                bold: "Sign Up: ",
                title: "Contact Coach Kari at (801) 717-2980 (text only) or kariigwe@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Swim Team (Per Month Plan) Fall/Winter/Spring",
        cost: "40",
        middle: true,
        bumpUp: false,
        showButton: false,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "Pay $40 per month"
            },
            {
                bold: "Requirements: ",
                title: "Must be able to swim front stroke and back stroke at least one length of the pool, unassisted. Must be between 5-13 years of age."
            },
            {
                bold: "Expectations: ",
                title: "This is an introductory swim team! We work on strokes and techniques and prepare the kids for more advanced swim teams!"
            },
            {
                bold: "When: ",
                title: "Saturday mornings for one hour."
            },
            {
                bold: "Sign Up: ",
                title: "Contact Coach Kari at (801) 717-2980 (text only) or kariigwe@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Swim Team (Family Plan) Fall/Winter/Spring ",
        cost: "100",
        middle: true,
        bumpUp: false,
        showButton: false,
        cardSize: 4,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "Have 3 or more kids on the team"
            },
            {
                bold: "Requirements: ",
                title: "Must be able to swim front stroke and back stroke at least one length of the pool, unassisted. Must be between 5-13 years of age."
            },
            {
                bold: "Expectations: ",
                title: "This is an introductory swim team! We work on strokes and techniques and prepare the kids for more advanced swim teams!"
            },
            {
                bold: "When: ",
                title: "Saturday mornings for one hour."
            },
            {
                bold: "Sign Up: ",
                title: "Contact Coach Kari at (801) 717-2980 (text only) or kariigwe@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Swim Team (Per Month Plan) Summer",
        cost: "75",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 6,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "Pay $75 per month"
            },
            {
                bold: "Requirements: ",
                title: "Must be able to swim front stroke and back stroke at least one length of the pool, unassisted. Must be between 5-13 years of age."
            },
            {
                bold: "Expectations: ",
                title: "This is an introductory swim team! We work on strokes and techniques and prepare the kids for more advanced swim teams!"
            },
            {
                bold: "When: ",
                title: "Twice a week in the mornings and meets every other Saturday."
            },
            {
                bold: "Sign Up: ",
                title: "Contact Coach Kari at (801) 717-2980 (text only) or kariigwe@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Swim Team (Family Plan) Summer ",
        cost: "200",
        middle: false,
        bumpUp: true,
        showButton: false,
        cardSize: 6,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "$200 per month for families with 3 or more kids on the team"
            },
            {
                bold: "Requirements: ",
                title: "Must be able to swim front stroke and back stroke at least one length of the pool, unassisted. Must be between 5-13 years of age."
            },
            {
                bold: "Expectations: ",
                title: "This is an introductory swim team! We work on strokes and techniques and prepare the kids for more advanced swim teams!"
            },
            {
                bold: "When: ",
                title: "Twice a week in the mornings and meets every other Saturday."
            },
            {
                bold: "Sign Up: ",
                title: "Contact Coach Kari at (801) 717-2980 (text only) or kariigwe@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Adult Swimming Group Lessons (Per Lesson Plan)",
        cost: "10",
        middle: true,
        bumpUp: false,
        showButton: false,
        cardSize: 6,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Lesson: ",
                title: "$10 per lesson while swiming with a group"
            },
            {
                bold: "Requirements: ",
                title: "Must be 18+ and already know how to swim."
            },
            {
                bold: "Expectations: ",
                title: "Improve your swimming technique and get in a workout."
            },
            {
                bold: "When: ",
                title: "Tuesday and Thursday evenings from 7:30-8:30"
            },
            {
                bold: "Sign Up: ",
                title: "Contact Coach Kari at (801) 717-2980 (text only) or kariigwe@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Adult Swimming Group Lessons (Per Month Plan)",
        cost: "70",
        middle: true,
        bumpUp: false,
        showButton: false,
        cardSize: 6,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Pay Per Month: ",
                title: "$10 per lesson while swiming with a group"
            },
            {
                bold: "Requirements: ",
                title: "Must be 18+ and already know how to swim."
            },
            {
                bold: "Expectations: ",
                title: "Improve your swimming technique and get in a workout."
            },
            {
                bold: "When: ",
                title: "Tuesday and Thursday evenings from 7:30-8:30"
            },
            {
                bold: "Sign Up: ",
                title: "Contact Coach Kari at (801) 717-2980 (text only) or kariigwe@utahswimacademy.com"
            },
        ]
    },
    {
        title: "Adult Swimming Private Lessons",
        cost: "19-52",
        middle: false,
        bumpUp: false,
        showButton: false,
        cardSize: 12,
        button: {
            link: "https://swim-academy.herokuapp.com/our_lessons",
            title: "Buy Lessons",
            color: "info"
        },
        list: [
            {
                bold: "Private Lessons: ",
                title: "20 - 40 minutes lessons"
            },
            {
                bold: "Prices: ",
                title: "Prices are the same as the regular lessons and purchased through the website."
            },
            {
                bold: "When: ",
                title: "Flexible schedule by appointment only "
            },
            {
                bold: "Requirements: ",
                title: "For any skill level"
            },
            {
                bold: "Sign Up: ",
                title: "Contact Coach Kari at (801) 717-2980 (text only) or kariigwe@utahswimacademy.com"
            },
        ]
    },
]