/*eslint-disable*/
import React from "react";
// Google Analytics Tracker
import Tracker from "useTracking.js"
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import SectionMap from "views/ContactUsPage/Sections/SectionMap.js";
import SectionInfo from "views/ContactUsPage/Sections/SectionInfo.js";

export default function ContactUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Tracker();
  }, []);
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <SectionMap />
      <SectionInfo />
      <Footer theme="white" />
    </div>
  );
}
