/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Google Analytics Tracker
import Tracker from "useTracking.js"
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import SectionTerms from "views/TermsPage/Sections/SectionTerms.js";

import termsStyle from "assets/jss/material-kit-pro-react/views/termsStyle.js";

const useStyles = makeStyles(termsStyle);

export default function TermsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Tracker();
  });
  const getMonth = () => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    return monthNames[d.getMonth()];
  }
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/headers/terms.jpg")}
        small
        title="Terms of Service"
        description={`Last Updated ${getMonth()} ${(new Date().getFullYear())}`}
        backgroundPosition={"center center"} />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <SectionTerms />
        </div>
      </div>
      <Footer theme="white" />
    </div>
  );
}
