/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// Google Analytics Tracker
import Tracker from "useTracking.js"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import SectionQuestions from "views/FaqPage/Sections/SectionQuestions.js";

import faqStyle from "assets/jss/material-kit-pro-react/views/faqStyle.js";

const useStyles = makeStyles(faqStyle);

export default function FaqPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Tracker();
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/headers/faq.jpg")}
        small
        title="FAQs"
        description="How can we help you?"
        backgroundPosition={"center bottom"} />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <SectionQuestions />
        </div>
      </div>
      <Footer theme="white" />
    </div>
  );
}
