import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// @material-ui/icons
import People from "@material-ui/icons/People";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

import pricingStyle from "assets/jss/material-kit-pro-react/views/teamSections/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.pricing}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter}>
              <h2 className={classes.title}>Selecting The Perfect Instructor</h2>
              <h4 className={classes.description}>Begins With Knowing Your Child's Personality</h4>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing>
                  <h4 className={classes.cardCategory}>TYPE 1 PERSONALITY</h4>
                  <div className={classes.iconWrapper}>
                    <People className={classNames(classes.icon,classes.iconVertical,classes.warning)} />
                  </div>
                  <ul>
                    <li>
                      Fun Loving
                    </li>
                    <li>
                      Social
                    </li>
                    <li>
                      Spontaneous
                    </li>
                    <li>
                      Easily Distracted
                    </li>
                  </ul>
                </CardBody>
                <CardFooter pricing className={classes.justifyContentCenter}>
                  <p className={classes.personalDescription}>Your Child needs an instructor that will keep them on task but still makes things fun during their lessons!</p>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing>
                  <h4 className={classes.cardCategory}>TYPE 2 PERSONALITY</h4>
                  <div className={classes.iconWrapper}>
                    <People className={classNames(classes.icon,classes.iconVertical,classes.primary)} />
                  </div>
                  <ul>
                    <li>
                      Sensitive
                    </li>
                    <li>
                      Calm
                    </li>
                    <li>
                      Soft Spoken
                    </li>
                    <li>
                      Shy
                    </li>
                  </ul>
                </CardBody>
                <CardFooter pricing className={classes.justifyContentCenter}>
                  <p className={classes.personalDescription}>Your Child needs an instructor that can reassure them during this experience and be extra loving!</p>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing>
                  <h4 className={classes.cardCategory}>TYPE 3 PERSONALITY</h4>
                  <div className={classes.iconWrapper}>
                    <People className={classNames(classes.icon,classes.iconVertical,classes.info)} />
                  </div>
                  <ul>
                    <li>
                      Determined
                    </li>
                    <li>
                      Independent
                    </li>
                    <li>
                      Busy
                    </li>
                    <li>
                      Stubborn
                    </li>
                  </ul>
                </CardBody>
                <CardFooter pricing className={classes.justifyContentCenter}>
                  <p className={classes.personalDescription}>Your Child needs an instructor that will keep them on track & not allow them to control the lesson.</p>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing>
                  <h4 className={classes.cardCategory}>TYPE 4 PERSONALITY</h4>
                  <div className={classes.iconWrapper}>
                    <People className={classNames(classes.icon,classes.iconVertical,classes.danger)} />
                  </div>
                  <ul>
                    <li>
                      Intellectual 
                    </li>
                    <li>
                      Exact
                    </li>
                    <li>
                      Serious
                    </li>
                    <li>
                      Logical
                    </li>
                  </ul>
                </CardBody>
                <CardFooter pricing className={classes.justifyContentCenter}>
                  <p className={classes.personalDescription}>Your Child needs consistency, and an instructor who can do more explaining during the lesson.</p>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter}>
              <h3 className={classes.subtitle}>These Types of Personalities are from the book<br />"Child Whisperer" by Carol Tuttle</h3><br />
              <Button color="info" round href="https://cw.liveyourtruth.com/" target="_blank" size="lg">
                    LEARN MORE FROM "CHILD WHISPERER"
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
