/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Google Analytics Tracker
import Tracker from "useTracking.js"
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// sections for this page
import SectionBody from "views/SalesPage/Sections/SectionBody.js";
import SectionVideo from "views/SalesPage/Sections/SectionVideo.js";
import SectionReviews from "views/SalesPage/Sections/SectionReviews.js";
import SectionContact from "views/SalesPage/Sections/SectionContact.js";
// styles
import servicesStyle from "assets/jss/material-kit-pro-react/views/salesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SalesPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    Tracker();
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
      />
      <div className={classNames(classes.main)}>
      <GridContainer className={classNames(classes.container)}>
        <GridItem xs={12} lg={8} className={classes.mlAuto}>
          <SectionBody/>
        </GridItem>
        <GridItem xs={12} lg={4} className={classes.mlAuto,classes.sideBar}>
          <SectionVideo />
          <SectionContact />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} className={classes.mlAuto}>
          <SectionReviews />
        </GridItem>
      </GridContainer>
      </div>
      <Footer theme="white" />
    </div>
  );
}
