import React from "react";
// database
import * as DATABASE from 'db';
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing() {
  const classes = useStyles();
  return (
    <div className={classes.pricingSection}>
      <GridContainer>
      <GridItem md={12} sm={12} className={classNames(classes.mlAuto,classes.mrAuto,classes.textCenter)}></GridItem>
        {DATABASE.PROMO_PRICE_LIST.map((item,i) => 
        <GridItem md={4} sm={4} key={item.title+i}>
          <Card raised pricing color={item.middle ? "info" : undefined} style={{ marginTop: item.middle ? window.innerWidth > 900 ? -30 : 30 : 20 }}>
            <CardBody pricing>
              <h6 className={classNames(classes.cardCategory, !item.middle && classes.textInfo)}>
                {item.title}
              </h6>
              <h1 className={item.middle ? classes.cardTitleWhite : classes.cardTitle}>
                <small>$</small>{item.cost}
              </h1>
              <ul>
                {item.list.map((listItem,index) =>
                  <li key={item.title+listItem.title+index}>
                    <b>{listItem.bold}</b> {listItem.title}
                  </li>
                )}
                {item.middle && 
                <li>
                  <br />
                </li>
                }
              </ul>
              {item.button && <Button href={item.button.link} target="_blank" color={item.button.color} round>
                {item.button.title}
              </Button>}
            </CardBody>
          </Card>
        </GridItem>
        )}
      </GridContainer>
      <GridContainer className={classes.border}>
        <GridItem
          md={12}
          sm={12}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h1 className={classes.finalSaleTitle}>ALL SALES ARE FINAL</h1>
          <h4 className={classes.finalSaleDescription}>No refunds unless authorized</h4>
          <p>Terms: Lessons expire 1 year from purchase, unless otherwise specified.<br />To cancel a scheduled swimming lesson, you must do so at least 24 hours in advance. By purchasing lessons, you agree to these terms.</p>
          <p>* This package deal can be split between multiple students.</p>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.border}>
        <GridItem
          md={12}
          sm={12}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h1 className={classes.finalSaleTitle}>CANCELLATION POLICY</h1>
          <h4 className={classes.finalSaleDescription}>Lessons can be canceled up to 24 hours before the scheduled lesson</h4>
          <p>If something comes up within 24 hours of the lesson (sickness, appointment, etc), you can offer the lesson to another swim family (GroupMe, Facebook VIParents, etc), bring a sibling, or trade with someone else.</p>
          <p>We understand kids get sick and sometimes it is last minute. We will allow <strong>one same-day cancellation per child.*</strong><br />If your child wakes up sick, contact us at <a className={classes.link} href={`tel:${DATABASE.SUPPORT_PHONE_NUMBER}`} target="_blank" rel="noopener noreferrer">{DATABASE.SUPPORT_PHONE_NUMBER}</a> <strong>before 10 AM</strong>.<br />The lesson will then be credited back to your account and you can reschedule another time. </p>
          <p>Please plan ahead and make sure your child is feeling 100% when you come for lessons. If your child is sick today, please cancel the next 2 days to ensure your child has been symptom free for at least 24 hours.</p>
          <p><strong>*One sick day per child where we will credit the lesson back to your account. Must be canceled by 10:00 AM to receive credit.*</strong></p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
