import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import People from "@material-ui/icons/People";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Pool from "@material-ui/icons/Pool";
import AttachMoney from "@material-ui/icons/AttachMoney";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";

import servicesStyle from "assets/jss/material-kit-pro-react/views/pricingSections/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionServices({...props}) {
  const classes = useStyles();
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={12}
          sm={12}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>{props.description ? props.description : "GETTING STARTED"}</h2>
          <h5 className={classes.description}>
            To get started, you'll need to either login or create an account with us.
          </h5>
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer>
        <GridItem lg={3} md={6} sm={12}>
          <InfoArea
            title="1. Create An Account"
            description={
              <span>
                <p className={classes.paragraphs}>
                  Create an account to access all your swim needs. Purchase lessons and schedule them for your child. Create a portfolio of your child to keep track of their lessons.{" "}
                </p>
                <Button href="https://swim-academy.herokuapp.com/users/sign_in" color="info" target="_blank" fullWidth>
                  Create An Account
                </Button>
              </span>
            }
            icon={ExitToApp}
            iconColor="success"
          />
        </GridItem>
        <GridItem lg={3} md={6} sm={12}>
          <InfoArea
            title="2. Buy Lessons"
            description={
              <span>
                <p className={classes.paragraphs}>
                  Once you're logged in, click on "Buy Lessons" in the upper left corner menu and you'll be able to select the amount of lessons you wish to buy. Each lesson is 20 minutes.{" "}
                </p>
                <Button href="https://swim-academy.herokuapp.com/our_lessons" color="info" target="_blank" fullWidth>
                  Login
                </Button>
              </span>
            }
            icon={AttachMoney}
            iconColor="warning"
          />
        </GridItem>
        <GridItem lg={3} md={6} sm={12}>
          <InfoArea
            title="3. Schedule Lessons"
            description={
              <span>
                <p className={classes.paragraphs}>
                  Use our self-scheduling system to schedule your child with one of our Swim Instructors. Learn more about our team and find the perfect instructor for your child. Expect new students to come 4-5 times per week for 2-3 weeks.{" "}
                </p>
                <Button href="/team" color="info" fullWidth>
                  Our Team
                </Button>
              </span>
            }
            icon={People}
            iconColor="primary"
          />
        </GridItem>
        <GridItem lg={3} md={6} sm={12}>
          <InfoArea
            title="4. Swim & Learn"
            description={
              <span>
                <p className={classes.paragraphs}>
                  Bring your child's swim suit, a towel, and a swim diaper (if not potty trained). We recommend getting a reusable one, which you can buy online. Please DO NOT get in the pool during lessons, the pool is only for those who are taking lessons.{" "}
                </p>
                <Button href="/approach" color="info" fullWidth>
                  Our Approach
                </Button>
              </span>
            }
            icon={Pool}
            iconColor="info"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
