// IMAGES MUST BE 800 x 800

// import blankAvatar from "assets/img/team/blank.jpg"
import joD from "assets/img/team/joD.jpg"
import chelseaONeal from "assets/img/team/chelseaONeal.jpg"
// import abbieSwindler from "assets/img/team/abbieSwindler.jpg"
import alissaHokanson from "assets/img/team/alissaHokanson.jpg"
// import alliSmith from "assets/img/team/alliSmith.jpg"
// import allysonPotter from "assets/img/team/allysonPotter.jpg"
import autumnAkiu from "assets/img/team/autumnAkiu.jpg"
// import brandyMolina from "assets/img/team/brandyMolina.jpg"
// import brookeSpotten from "assets/img/team/brookeSpotten.jpg"
// import emileeWorkman from "assets/img/team/emileeWorkman.jpg"
// import enaKaaumoana from "assets/img/team/enaKaaumoana.jpg"
// import ericaBergman from "assets/img/team/ericaBergman.jpg"
// import janeHarvey from "assets/img/team/janeHarvey.jpg"
// import kesslynZarate from "assets/img/team/kesslynZarate.jpg"
// import lindseyHalvorson from "assets/img/team/lindseyHalvorson.jpg"
// import madisonMalmgren from "assets/img/team/madisonMalmgren.jpg"
// import maLeaMacham from "assets/img/team/maLeaMacham.jpg"
// import meganKrebs from "assets/img/team/meganKrebs.jpg"
// import mishayMecham from "assets/img/team/mishayMecham.jpg"
// import nicoleHokanson from "assets/img/team/nicoleHokanson.jpg"
import kayleenOlson from "assets/img/team/kayleenOlson.jpg"
import ashlyeHokanson from "assets/img/team/ashlyeHokanson.jpg"
// import stevenBlake from "assets/img/team/stevenBlake.jpg"
// import addieNalder from "assets/img/team/addieNalder.jpg"
// import taylorHalvorson from "assets/img/team/taylorHalvorson.jpg"
// import madiAllphin from "assets/img/team/madiAllphin.jpg"
// import julianaGagliardi from "assets/img/team/julianaGagliardi.jpg"
import kellyLewis from "assets/img/team/kellyLewis.jpg"
// import meganWiscombe from "assets/img/team/meganWiscombe.jpg"
// import alanaAkiu from "assets/img/team/alanaAkiu.jpg"
// import madalinCarlson from "assets/img/team/madalinCarlson.jpg"
// import maxPetersen from "assets/img/team/maxPetersen.jpg"
// import abbeyEnce from "assets/img/team/abbeyEnce.jpg"
import kariIgew from "assets/img/team/kariIgew.jpg"
import mia from "assets/img/team/mia.jpg"
// import taia from "assets/img/team/taia.jpg"
import regan from "assets/img/team/regan.jpg"
// import ashleea from "assets/img/team/ashleea.jpg"
// import gregs from "assets/img/team/gregs.jpg"
// import melodyc from "assets/img/team/melodyc.jpg"
// import sierraHooley from "assets/img/team/sierraHooley.jpg"
import aryYoung from "assets/img/team/ary-young.jpeg"
import briDelph from "assets/img/team/bri-delph.jpeg"
import lydiaPayne from "assets/img/team/lydia-payne.jpeg"
import avreyTucker from "assets/img/team/avery-tucker.jpeg"
import neleKaufusi from "assets/img/team/nele-kaufusi.jpeg"
import samKirkby from "assets/img/team/sam-kirkby.jpeg"
import sierraSedivy from "assets/img/team/sierra-sedivy.jpeg"
import kloiePellegrini from "assets/img/team/kloie-pellegrini.jpg"
import annaNorman from "assets/img/team/anna-norman.jpg"

export const TEAM_MEMBERS = [
  {
    img: joD,
    name: "JoD Jones",
    title: "Owner / Founder",
    primary: "0",
    secondary: "0",
    work: `As the owner of Utah Swim Academy, JoD (pronounced Jody) has been a swim instructor and coach for over ${(new Date().getFullYear() - 1983)} years. Teaching over thousands of students she finished her online swim instructor course for parents and teachers. In addition to being an American Red Cross Lifeguard, JoD is also a certified Water Safety Instruction Trainer.`,
    experience: "JoD has moved to St. George to start her retirement.",
    admin: true,
    email: "",
    phone: "801.636.2708"
  },
  {
    img: chelseaONeal,
    name: "Chelsea O'Neal",
    title: "Manager",
    primary: "4",
    secondary: "2",
    work: `Chelsea works well with all types and loves to encourage her students while pushing them with their progression. She is firm in a patient way. She spends more time with day to day operations but tries to be in the pool as much as she can with the students.`,
    experience: `Chelsea has been teaching for ${(new Date().getFullYear() - 2006)} years (2006). She has been on the swim team since 10 years old and has coached the swim team for 13 years. She's CPR certified and Survival teaching certified.`,
    admin: true,
    email: "",
    phone: "801.636.2454"
  },
  {
    img: alissaHokanson,
    name: "Alissa Hokanson",
    title: "Head Instructor",
    primary: "2",
    secondary: "3",
    started: "November 2019",
    work: "Babies, toddlers, adults, students with disabilities, and students working on stroke work",
    language: "English",
    experience: `Was on the swim team from age 6 to 17. Taught swimming in High School.`,
    goals: "To get kids safe in the water to also have fun and enjoy swimming.",
    hobbies: "Loves watching children get excited about swimming and having fun in the water.",
    email: "alissah@utahswimacademy.com",
    phone: "385.204.5156"
  },
  {
    img: autumnAkiu,
    name: "Autumn Akiu",
    title: "Instructor",
    primary: "2",
    secondary: "4",
    started: "January 2020",
    work: "Babies, toddlers, students with disabilities and students working on stroke work",
    language: "English, Japanese",
    experience: "4 years of competitive swimming and 4 years of water polo during highschool. Her second year during competitive swimming, she helped younger teammates with techniques for competitions and swimming workouts.",
    goals: "To get anyone who takes lessons confident in themselves and their strengths.",
    hobbies: "Loves seeing the improvement in individuals and the excitement children show when they get something for the first time.",
    email: "autumna@utahswimacademy.com",
    phone: "385.985.3033"
  },
  {
    img: kayleenOlson,
    name: "Kayleen Olson",
    title: "(On Temporary Leave)",
    primary: "1",
    secondary: "3",
    started: "June 2020",
    work: "Babies, toddlers, students with disabilities and students working on stroke work",
    language: "English",
    experience: "Swam in high school, taught the swim team this past summer and was a certified lifeguard for 3 years.",
    goals: "To help any kid who comes to her be safe in the water, but still able to have fun!",
    hobbies: "She loves the kids, all of the different funny and sweet cute kids. They always brighten her day and makes her so happy being able to help them.",
    email: "kayleeno@utahswimacademy.com",
    phone: "385.309.1836"
  },
  {
    img: ashlyeHokanson,
    name: "Ashlye Hokanson",
    title: "Head Instructor",
    primary: "1",
    secondary: "3",
    started: "June 2020",
    work: "Babies & toddlers",
    language: "English",
    experience: `Took private swim lessons growing up.`,
    goals: "Getting kids to love the water and be able to trust me and themselves!",
    hobbies: "Loves watching kids learn to love the water and get over their fears, it’s so rewarding!",
    email: "ashlyeh@utahswimacademy.com",
    phone: "801.900.4802"
  },
  {
    img: kellyLewis,
    name: "Kelly Lewis",
    title: "Instructor",
    primary: "1",
    secondary: "3",
    started: "January 2021",
    work: "Babies & toddlers",
    language: "English",
    experience: "Was on the swim team for 3 years and played water polo for 2 years.",
    goals: "Hoping to play competitive volley ball in High School and serve a L.D.S. Mission.",
    hobbies: "Basketball, volley ball, surfing, being in the water, and hanging out with friends.",
    email: "kellyl@utahswimacademy.com",
    phone: "385.204.6314"
  },
  {
    img: kariIgew,
    name: "Kari Igwe",
    title: "Swim Coach",
    primary: "2",
    secondary: "3",
    started: "May 2021",
    work: "Swim team and adult students",
    language: "English",
    experience: "Has 5 years of swim team, 3 years of lifequard, and 7 years of teaching swim.",
    goals: "Her goal is to challenge her students while also making it fun! Her goal is to always see her students trying their hardest!",
    hobbies: "She loves teaching kids and helping them be safe! As a parent, she loves the relief that comes knowing her kids are safe in the water. She loves teaching adults and helping them to learn and refine a skill!",
    email: "kariigwe@utahswimacademy.com",
    phone: "801.717.2980"
  },
  {
    img: regan,
    name: "Regan",
    title: "(On Temporary Leave)",
    primary: "2",
    secondary: "1",
    started: "September 2021",
    work: "Babies, toddlers, and students working on stroke work",
    language: "English",
    experience: "Was on the high school swim team and also in a club team. She swam competitively for about 8 years. She worked in Salt Lake City teaching swim lessons for 6 months.",
    goals: "Helping all of the kids to feel confident and safe.",
    hobbies: "Her favorite thing is finding out what works for each kid and then seeing them progress. She loves how happy it makes them when they start progressing.",
    email: "reganm@utahswimacademy.com",
    phone: "385.448.0935"
  },
  {
    img: mia,
    name: "Mia Cardon",
    title: "Instructor",
    primary: "1",
    secondary: "2",
    started: "January 2022",
    work: "Babies, toddlers, and students working on stroke work",
    language: "English",
    experience: "Started swimming at 2 years old. Tour team when she was 7 and 8 in Germany, local swim team when she was 11 in Washington.",
    goals: "She want the kids to be comfortable in the water and believe that they can do it.",
    hobbies: "She loves watching them grow.",
    email: "miacardon@utahswimacademy.com",
    phone: "385.497.5309"
  },
  {
    img: briDelph,
    name: "Bri Delph",
    title: "Instructor",
    primary: "2",
    secondary: "4",
    started: "February 2022",
    work: "Babies, toddlers, students with disabilities and students working on stroke work",
    language: "English",
    experience: "Was on the swim team for 1 year.",
    goals: "To make sure that she teaches lessons that will most benefit that kid whether it is working on physical swimming techniques or gaining confidence in the water. She wants to show her kids that she is dedicated to them and they’re swimming!",
    hobbies: "Loves watching kids progress and seeing how far they come in their swimming. One of her favorite things about teaching is when her kids show how proud they are of the things they’ve achieved.",
    email: "bridelph@utahswimacademy.com",
    phone: "801.900.3580"
  },
  {
    img: lydiaPayne,
    name: "Lydia Payne",
    title: "Instructor",
    primary: "4",
    secondary: "2",
    started: "June 2022",
    work: "Babies and toddlers",
    language: "English",
    experience: "No formal swim training but has a love for swimming, and water safety. Was a lifeguard for 4 years.",
    goals: "For them to be comfortable with being uncomfortable. Swim lessons can be so intimidating for kids. She wants her students to feel safe and supported when pushing them out of their comfort zone, so they can trust her, themselves and their abilities, in and out of the pool.",
    hobbies: "Getting to work with all different types of kids. She could be having the worst day but when she gets in the water to teach, her students always makes her feel better!",
    email: "lydiap@utahswimacademy.com",
    phone: "385.437.0555"
  },
  {
    img: avreyTucker,
    name: "Avrey Tucker",
    title: "Instructor",
    primary: "2",
    secondary: "4",
    started: "June 2022",
    work: "Babies, toddlers",
    language: "English, ASL",
    experience: "Has swam swim recreationally for 1 year.",
    goals: "To see a kid learn to LOVE swimming! She also loves to see them gain their own confidence and independence",
    hobbies: "Seeing their cute smiles when they jump into the pool!",
    email: "averyt@utahswimacademy.com",
    phone: "801.900.4016"
  },
  {
    img: sierraSedivy,
    name: "Sierra Sedivy",
    title: "Head Instructor",
    primary: "3",
    secondary: "4",
    started: "July 2022",
    work: "Babies, toddlers, students with disabilities and students working on stroke work",
    language: "English, Czech",
    experience: `Sierra has been swimming competitively since she was 6 years old up through high school, and taught the swim team.`,
    goals: "Wants her kids to feel safe and accomplished when they leave a lesson.",
    hobbies: "Having fun and laughing with kids, and seeing their progress.",
    email: "sierras@utahswimacademy.com",
    phone: "801.717.8145"
  },
  {
    img: samKirkby,
    name: "Sam Kirkby",
    title: "Instructor",
    primary: "1",
    secondary: "2",
    started: "November 2022",
    work: "Babies, toddlers and older kids working on stroke work",
    language: "English",
    experience: "Swim team from 8-12 years old and lifeguard for 2 years.",
    goals: "Some kind of progress no matter how small at every lesson.",
    hobbies: "The connection with the kids!!!",
    email: "samanthak@utahswimacademy.com",
    phone: "385.200.0525"
  },
  {
    img: aryYoung,
    name: "Ary Young",
    title: "Instructor",
    primary: "3",
    secondary: "1",
    started: "January 2023",
    work: "Babies, toddlers, and students working on stroke work",
    language: "English",
    experience: "Swam on a competitive swim team (UVRays) for 7 years.",
    goals: "To help the kids learn how to swim and for them to have fun doing it.",
    hobbies: "Loves seeing the excitement each of the kids have as they progress.",
    email: "aryyoung@utahswimacademy.com",
    phone: "801.709.1085"
  },
  {
    img: neleKaufusi,
    name: "Nele Kaufusi",
    title: "Instructor",
    primary: "1",
    secondary: "2",
    started: "January 2023",
    work: "Babies and  toddlers",
    language: "English",
    experience: "Participated in club and high school swim team, taught club swim lessons in Salt Lake and in Texas for 3 years.",
    goals: "Her goals are for kids to be in a comfortable environment so they can learn swim safety, be confident in the water, and have a fun experience.",
    hobbies: "In teaching, she loves building one on one connections and teaching in a way that is fun and informative.",
    email: "nelekaufusi@utahswimacademy.com",
    phone: "801.477.6707"
  },
  {
    img: kloiePellegrini,
    name: "Kloie Pellegrini",
    title: "Instructor",
    primary: "1",
    secondary: "4",
    started: "February 2023",
    work: "Babies, toddlers and students with disabilities",
    language: "English",
    experience: "Began swimming in elementary school and loved it ever since. She mainly swims recreationally, but she's excited to be working with kids and teaching them crucial survival mechanisms in the water.",
    goals: "To make sure that the students come and get the most out of their lessons, leaving feeling confident in their swimming and excited to return because of how much fun they had in the process.",
    hobbies: "She loves helping students accomplish something they didn’t think was possible for them to do before. The smile that beams on their faces and the peace and confidence they start to feel when swimming is such an incredible reward.",
    email: "kloiepellegrini@utahswimacademy.com",
    phone: "385.215.9051"
  },
  {
    img: annaNorman,
    name: "Anna Norman",
    title: "Instructor",
    primary: "1",
    secondary: "4",
    started: "February 2023",
    work: "Babies, toddlers, students with disabilities and students working on stroke work",
    language: "English, Spanish",
    experience: "Grew up with a pool in her backyard and spending weekends at a lake house. She can not remember not being able to swim. She taught swim lessons for 4 years in high school, as well as being part of the swim team.",
    goals: "To help children learn to be safe in the water, so they can enjoy swimming and feel comfortable and happy in the pool.",
    hobbies: "She loves working with each individual. Every person and every child is unique! She loves identifying children’s weaknesses and strengths so she can better adapt her teaching to meet their needs.",
    email: "annanorman@utahswimacademy.com",
    phone: "801.709.0896"
  }
];
