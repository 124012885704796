/*eslint-disable*/
import React, { useState } from "react";
// database
import * as DATABASE from 'db';
// nodejs library that concatenates classes
import classNames from "classnames";
// Calls
import axios from 'axios';
// react components used to create a google map
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Business from "@material-ui/icons/Business";
import Pool from "@material-ui/icons/Pool";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

const useStyles = makeStyles(contactUsStyle);
const API_PATH = 'https://www.utahswimacademy.com/mail.php';

export default function ContactUsPage() {
  const classes = useStyles();
  const [formData, setFormData] = useState({name: '', email: '', message: '', mailSent: false, error: null, showButton: true});
  const handleFormSubmit = (e) => {
      e.preventDefault();
      setFormData({...formData, showButton: false, error: null, mailSent: false});
      if(formData.name == ""){
        setFormData({...formData, showButton: true, error: "Name is Blank", mailSent: false});
      } else if(formData.email == ""){
        setFormData({...formData, showButton: true, error: "Email is Blank", mailSent: false});
      } else if(formData.message == ""){
        setFormData({...formData, showButton: true, error: "Message is Blank", mailSent: false});
      } else {
        axios({
          method: 'post',
          url: `${API_PATH}`,
          headers: { 'content-type': 'application/json' },
          data: formData
        })
          .then(result => {
            setFormData({...formData, showButton: false, error: null, mailSent: result.data.sent})
          })
          .catch(error => setFormData({...formData, error: error.message, showButton: true}));
      }
  };
  return (
    <div>
      <div className={classNames(classes.main)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer>
              {window.innerWidth > 900 &&
              <GridItem md={5} sm={5}>
              <h2 className={classes.title}>Send us a message</h2>
                <p>
                  You can contact us with anything related to our services.<br />We
                  {"'"}ll get in touch with you within one business day.
                  <br />
                  <br />
                </p>
                <form action="#">
                  <CustomInput
                    labelText="Your Name"
                    name="name"
                    update={(e) => setFormData({...formData, name: e.target.value})}
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Email address"
                    name="email"
                    update={(e) => setFormData({...formData, email: e.target.value})}
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Your message"
                    name="message"
                    update={(e) => setFormData({...formData, message: e.target.value})}
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 6
                    }}
                  />
                  <div className={classes.textCenter}>
                    {formData.error &&
                      <div style={{ color: 'red', fontWeight: 400 }}>Message Not Sent...{formData.error}</div>
                    }
                  </div>
                  <div className={classes.textCenter}>
                    {formData.showButton &&
                    <Button color="info" round onClick={(e) => handleFormSubmit(e)} value="Submit" type="submit">
                      Contact us
                    </Button>
                    }
                  </div>
                  <div className={classes.textCenter}>
                    {formData.mailSent &&
                      <div tyle={{ color: 'green', fontWeight: 400 }}>Thank you for contacting us!<br />Your message has been sent and we will reply shortly.</div>
                    }
                  </div>
                </form>
              </GridItem>
              }
              <GridItem md={3} sm={3} className={classes.mlAuto}>
                <br />
                <InfoArea
                  className={classes.info}
                  title="Where To Find Us"
                  description={
                    <p>
                      Address:<br />
                      <a className={classes.link} href={`https://www.google.com/maps/place/Utah+Swim+Academy/@40.2486483,-111.7023,15z/data=!4m2!3m1!1s0x0:0x8e6257bf385c8cb5?sa=X&ved=2ahUKEwiJk7HH16XqAhULt54KHZACB04Q_BIwDHoECBMQCA`} target="_blank">1145 N 2050 W (Geneva Rd.), <br /> Provo, Utah{" "}
                      <br /> 84601</a>
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="info"
                />
                <br />
                <InfoArea
                  className={classes.info}
                  title="Pool Hours"
                  description={
                    <GridContainer>
                      <GridItem md={5} xs={5}>
                        <p>
                          {DATABASE.POOL_HOURS[0].day}:<br />
                          {DATABASE.POOL_HOURS[1].day}:<br />
                          {DATABASE.POOL_HOURS[2].day}:<br />
                          {DATABASE.POOL_HOURS[3].day}:<br />
                          {DATABASE.POOL_HOURS[4].day}:<br />
                          {DATABASE.POOL_HOURS[5].day}:<br />
                          {DATABASE.POOL_HOURS[6].day}:<br />
                          {DATABASE.POOL_HOURS[7].day}:<br />
                        </p>
                      </GridItem>
                      <GridItem md={7} xs={7}>
                        <p>
                          {DATABASE.POOL_HOURS[0].hours}<br />
                          {DATABASE.POOL_HOURS[1].hours}<br />
                          {DATABASE.POOL_HOURS[2].hours}<br />
                          {DATABASE.POOL_HOURS[3].hours}<br />
                          {DATABASE.POOL_HOURS[4].hours}<br />
                          {DATABASE.POOL_HOURS[5].hours}<br />
                          {DATABASE.POOL_HOURS[6].hours}<br />
                          {DATABASE.POOL_HOURS[7].hours}<br />
                        </p>
                      </GridItem>
                    </GridContainer>
                  }
                  icon={Pool}
                  iconColor="info"
                />
              </GridItem>
              <GridItem md={3} sm={3} className={classes.mlAuto}>
              <br />
                <InfoArea
                  className={classes.info}
                  title="How To Contact Us"
                  description={
                    <p>
                      Call or Text us at:<br />
                      <a className={classes.link} href={`tel:${DATABASE.SUPPORT_PHONE_NUMBER}`} target="_blank">{DATABASE.SUPPORT_PHONE_NUMBER}</a><br />
                      Email us at:<br />
                      <a className={classes.link} href={`mailto:${DATABASE.SUPPORT_EMAIL}?subject=Utah Swim Academy`} target="_blank">{DATABASE.SUPPORT_EMAIL}</a>
                    </p>
                  }
                  icon={Phone}
                  iconColor="info"
                />
                <br />
                <InfoArea
                  className={classes.info}
                  title="Office Hours"
                  description={
                    <GridContainer>
                      <GridItem md={5} xs={5}>
                        <p>
                          {DATABASE.OFFICE_HOURS[0].day}:<br />
                          {DATABASE.OFFICE_HOURS[1].day}:<br />
                          {DATABASE.OFFICE_HOURS[2].day}:<br />
                          {DATABASE.OFFICE_HOURS[3].day}:<br />
                          {DATABASE.OFFICE_HOURS[4].day}:<br />
                          {DATABASE.OFFICE_HOURS[5].day}:<br />
                          {DATABASE.OFFICE_HOURS[6].day}:<br />
                          {DATABASE.OFFICE_HOURS[7].day}:<br />
                        </p>
                      </GridItem>
                      <GridItem md={7} xs={7}>
                        <p>
                          {DATABASE.OFFICE_HOURS[0].hours}<br />
                          {DATABASE.OFFICE_HOURS[1].hours}<br />
                          {DATABASE.OFFICE_HOURS[2].hours}<br />
                          {DATABASE.OFFICE_HOURS[3].hours}<br />
                          {DATABASE.OFFICE_HOURS[4].hours}<br />
                          {DATABASE.OFFICE_HOURS[5].hours}<br />
                          {DATABASE.OFFICE_HOURS[6].hours}<br />
                          {DATABASE.OFFICE_HOURS[7].hours}<br />
                        </p>
                      </GridItem>
                    </GridContainer>
                  }
                  icon={Business}
                  iconColor="info"
                />
              </GridItem>
              {window.innerWidth < 901 &&
              <GridItem md={6} sm={6}>
              <h2 className={classes.title}>Send us a message</h2>
                <p>
                  You can contact us with anything related to our services.<br />We
                  {"'"}ll get in touch with you within one business day.
                  <br />
                  <br />
                </p>
                <form action="#">
                  <CustomInput
                    labelText="Your Name"
                    name="name"
                    update={(e) => setFormData({...formData, name: e.target.value})}
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Email address"
                    name="email"
                    update={(e) => setFormData({...formData, email: e.target.value})}
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Your message"
                    name="message"
                    update={(e) => setFormData({...formData, message: e.target.value})}
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 8
                    }}
                  />
                  <div className={classes.textCenter}>
                    {formData.error &&
                      <div style={{ color: 'red', fontWeight: 400 }}>Message Not Sent...{formData.error}</div>
                    }
                  </div>
                  <div className={classes.textCenter}>
                    {formData.showButton &&
                    <Button color="info" round onClick={(e) => handleFormSubmit(e)} value="Submit" type="submit">
                      Contact us
                    </Button>
                    }
                  </div>
                  <div className={classes.textCenter}>
                    {formData.mailSent &&
                      <div tyle={{ color: 'green', fontWeight: 400 }}>Thank you for contacting us!<br />Your message has been sent and we will reply shortly.</div>
                    }
                  </div>
                </form>
              </GridItem>
              }
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
