import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import ServicesPage from "views/ServicesPage/ServicesPage.js";
import PricingPage from "views/PricingPage/PricingPage.js";
import SpecialsPage from "views/SpecialsPage/SpecialsPage.js";
import SalesPage from "views/SalesPage/SalesPage.js";
import SchedulingPage from "views/SchedulingPage/SchedulingPage.js";
import TeamPage from "views/TeamPage/TeamPage.js";
import ApproachPage from "views/ApproachPage/ApproachPage.js";
import FirstPage from "views/FirstPage/FirstPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import FaqPage from "views/FaqPage/FaqPage.js";
import TermsPage from "views/TermsPage/TermsPage.js";
import PrivacyPage from "views/PrivacyPage/PrivacyPage.js";
import HomePage from "views/HomePage/HomePage.js";

var history = createBrowserHistory();

export const App = () => {
  return (
  <Router history={history}>
    <Switch>
      <Route path="/services" component={ServicesPage} />
      <Route path="/prices" component={PricingPage} />
      <Route path="/specials" component={SpecialsPage} />
      <Route path="/online-courses" component={SalesPage} />
      <Route path="/scheduling" component={SchedulingPage} />
      <Route path="/approach" component={ApproachPage} />
      <Route path="/first" component={FirstPage} />
      <Route path="/team" component={TeamPage} />
      <Route path="/contact" component={ContactUsPage} />
      <Route path="/faq" component={FaqPage} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/privacy" component={PrivacyPage} />
      <Route path="/" component={HomePage} />
    </Switch>
  </Router>
  )
}



ReactDOM.render(<App />, document.getElementById("root"));
