// import * as UTILS from 'utils';

export const BANNER = {
    show: false,
    name: "usa-show-banner-11-28-2022",
    link: "/",
    clickMe: "",
    title: `SOLD OUT -- BLACK FRIDAY SALE -- SOLD OUT`
}

export const PROMO_SHOW_ALL = false;

export const PROMO_HEADER = {
    default: true,
    defaultTitle: "No Specials",
    defaultDescription: "There are currently no specials going on right now",
    title: "Year End Sale!",
    description: "12/30/2021 - 01/01/2022"
}

export const PROMO_PRICE_LIST = [
    {
        title: "Bulk deal 15 Lessons",
        cost: "285",
        middle: false,
        // button: {
        //     link: "https://swim-academy.herokuapp.com/our_lessons",
        //     title: "Buy Lessons",
        //     color: "info"
        // },
        list: [
            {
                bold: "15",
                title: "Private 20 minute Lessons"
            },
            {
                bold: "+1",
                title: "Free Lesson"
            },
            {
                bold: "Expires",
                title: "December 31st, 2022"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    },
    // {
    //     title: "Bulk deal 20 Lessons",
    //     cost: "330",
    //     middle: true,
    //     // button: {
    //     //     link: "https://swim-academy.herokuapp.com/our_lessons",
    //     //     title: "Buy Lessons",
    //     //     color: "white"
    //     // },
    //     list: [
    //         {
    //             bold: "20",
    //             title: "Private 20 minute Lessons"
    //         },
    //         {
    //             bold: "+4",
    //             title: "Free Lessons"
    //         },
    //         {
    //             bold: "Expires",
    //             title: "December 30th, 2021"
    //         },
    //         {
    //             bold: "24",
    //             title: "Hours Advanced Cancellation"
    //         },
    //     ]
    // },
    {
        title: "Bulk deal 30 lessons",
        cost: "510",
        middle: false,
        // button: {
        //     link: "https://swim-academy.herokuapp.com/our_lessons",
        //     title: "Buy Lessons",
        //     color: "info"
        // },
        list: [
            {
                bold: "30",
                title: "Private 20 minute Lessons"
            },
            {
                bold: "+2",
                title: "Free Lessons"
            },
            {
                bold: "Expires",
                title: "December 31st, 2022"
            },
            {
                bold: "24",
                title: "Hours Advanced Cancellation"
            },
        ]
    }
];
