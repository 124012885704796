import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import * as UTILS from 'utils';
// Google Analytics Tracker
import Tracker from "useTracking.js"
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import AccordionComp from "components/Accordion/Accordion.js";
// sections of this Page
import SectionHeaders from "./Sections/SectionHeaders.js";
import SectionTeams from "./Sections/SectionTeams.js";
import SectionProjects from "./Sections/SectionProjects.js";
import SectionBook from "./Sections/SectionBook.js";
// Sections from outsource pages
import SectionSocialNetwork from "views/HomePage/Sections/SectionSocialNetwork.js";
import SectionSchedule from "views/SchedulingPage/Sections/SectionFeatures.js";
import SectionContact from "views/ApproachPage/Sections/SectionContact.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

const useStyles = makeStyles(homePageStyle);

export default function HomePage() {
  React.useEffect(() => {
    Tracker();
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0) {
      document.getElementById(href).scrollIntoView();
    }
    window.addEventListener("scroll", updateView);
    updateView();
    return function cleanup() {
      window.removeEventListener("scroll", updateView);
    };
  });
  const updateView = () => {
    var contentSections = document.getElementsByClassName("cd-section");
    var navigationItems = document
      .getElementById("cd-vertical-nav")
      .getElementsByTagName("a");

    for (let i = 0; i < contentSections.length; i++) {
      var activeSection =
        parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
      if (
        contentSections[i].offsetTop - window.innerHeight / 2 <
          window.pageYOffset &&
        contentSections[i].offsetTop +
          contentSections[i].scrollHeight -
          window.innerHeight / 2 >
          window.pageYOffset
      ) {
        navigationItems[activeSection].classList.add("is-selected");
      } else {
        navigationItems[activeSection].classList.remove("is-selected");
      }
    }
  };
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <div className={classes.main}>
        <SectionHeaders id="home" />
        <div className={classes.container}>
          <AccordionComp
          // active={0}
          activeColor="warning"
          collapses={[
            {
            title: `Yes We're Open! Click to View COVID-19 Public Health & Safety Guidelines as of ${UTILS.getMonth()} ${(new Date().getFullYear())}`,
              content: (
                <div>
                  <p>
                    Utah Swim Academy would like to announce that we have taken several steps to ensure the health and safety of not only our students, but of our instructors and parents/guardians as well. We have increased our strict protocols in maintaining a clean learning environment for our students in the pool in addition to continued cleanings of the perimeter & exterior pool area.
                  </p>
                  <p>
                    Because of this, we feel comfortable with our decision to remain open during our typical business hours. Neither the State of Utah, nor Utah County issued a lockdown, however we are prepared and will of course honor any lockdown if a decree is given.
                  </p>
                  <p>
                    The CDC has stated that there is no evidence that COVID-19 can spread to humans through the use of pools, hot tubs or water playgrounds with proper operation, maintenance, and disinfection (e.g., with chlorine and bromine). You can view what the CDC says about the Covid-19 virus and swimming pools if you click on this <a href="https://www.cdc.gov/coronavirus/2019-ncov/php/water.html" target="_blank" rel="noopener noreferrer" className={classes.link}>LINK</a>
                  </p>
                  <p>
                    The following are a few new policy changes we have made based on the social distancing protocols that have been recommended by the CDC and other federal health agencies. Please take note of the following changes and help us in the effort to keep everyone involved happy and healthy.
                  </p>
                  <p>
                    • Be extra cautious if your child or any other family members begin to show symptoms of any illness. If you become symptomatic in any form, then please stay home and we will accommodate you to get your lessons rescheduled.
                  </p>
                  {/* <p>
                    • To limit the amount of people inside the pool area at any given time, we ask that parents/guardians wait outside of the pool area until the completion of their scheduled lessons. You are free to wait outside in the yard area, in your vehicle or elsewhere as long as you pick up your child at the conclusion of their lesson(s). We also ask that siblings play/wait outside of the pool area as well.
                  </p> */}
                  <p>
                    • Our instructors have been notified to try and adhere to the CDC’s social distancing guidelines. With less people in the pool, our instructors can stay six to eight feet apart from each other during lessons. We ask that you participate as well, whether you’re dropping off your child or waiting outside until their lesson is complete.
                  </p>
                  <p>
                    • Please help in our efforts to keep the pool area clean and sanitized. Dispose of food, garbage or other germ-spreading items in the pool area and make sure that your children are clean before entering. Please feel free to use our bathroom areas to wash you and your children’s hands before & after lessons.
                  </p>
                  <p>
                    Please feel free to relay to us any other concerns/recommendations you might have about how we can keep our pool clean and virus free. We will remain open to all those who still wish to participate in their lessons, but we will also do our best to accommodate those who have concerns about bringing their children or those who have been affected by the COVID-19 virus.
                  </p>
                  <p>
                    Best wishes and happy swimming!
                  </p>
                  <p>
                    -The Utah Swim Academy Team
                  </p>
                </div>
              )
            }
          ]}
        />
        {/* <h1 style={{ fontSize: 24, color: 'orange', fontWeight: 700 }}>For COVID-19 safety, parents are currently not allowed to stay in the hard structure during lessons</h1> */}
        </div>
        <div className="cd-section" id="services">
          <SectionTeams />
          <div className={classes.container}><hr /></div>
          <SectionSchedule />
        </div>
        <SectionBook id="book" />
        <SectionProjects id="about" />
        <div className="cd-section" id="contacts">
          <SectionSocialNetwork />
          <SectionContact />
        </div>
        <Footer theme="white" />
      </div>
      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              href="#home"
              data-number="1"
              className="is-selected"
              onClick={e => {
                var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("home");
                }
              }}
            >
              {window.innerWidth > 900 && <span className="cd-dot" />}
              <span className="cd-label">HOME</span>
            </a>
          </li>
          <li>
            <a
              href="#services"
              data-number="2"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("services");
                }
              }}
            >
              {window.innerWidth > 900 && <span className="cd-dot" />}
              <span className="cd-label">SERVICES</span>
            </a>
          </li>
          <li>
            <a
              href="#book"
              data-number="3"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("book");
                }
              }}
            >
              {window.innerWidth > 900 && <span className="cd-dot" />}
              <span className="cd-label">SCHEDULE TODAY</span>
            </a>
          </li>
          <li>
            <a
              href="#about"
              data-number="4"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("about");
                }
              }}
            >
              {window.innerWidth > 900 && <span className="cd-dot" />}
              <span className="cd-label">ABOUT US</span>
            </a>
          </li>
          <li>
            <a
              href="#contacts"
              data-number="5"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("contacts");
                }
              }}
            >
              {window.innerWidth > 900 && <span className="cd-dot" />}
              <span className="cd-label">CONTACT US</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
