export const SUPPORT_EMAIL = "support@utahswimacademy.com";

export const SUPPORT_PHONE_NUMBER = "+1 (801) 367-0648";

export const OFFICE_HOURS = [
    {
        day: "Monday",
        hours: "9AM - 5PM"
    },
    {
        day: "Tuesday",
        hours: "9AM - 5PM"
    },
    {
        day: "Wednesday",
        hours: "9AM - 5PM"
    },
    {
        day: "Thursday",
        hours: "9AM - 5PM"
    },
    {
        day: "Friday",
        hours: "9AM - 5PM"
    },
    {
        day: "Saturday",
        hours: "Varies"
    },
    {
        day: "Sunday",
        hours: "Closed"
    },
    {
        day: "Holidays",
        hours: "Varies"
    }
];

export const POOL_HOURS = [
    {
        day: "Monday",
        hours: "8AM - 8PM"
    },
    {
        day: "Tuesday",
        hours: "8AM - 8PM"
    },
    {
        day: "Wednesday",
        hours: "8AM - 8PM"
    },
    {
        day: "Thursday",
        hours: "8AM - 8PM"
    },
    {
        day: "Friday",
        hours: "8AM - 8PM"
    },
    {
        day: "Saturday",
        hours: "8AM - 2PM"
    },
    {
        day: "Sunday",
        hours: "Closed"
    },
    {
        day: "Holidays",
        hours: "Varies"
    }
];