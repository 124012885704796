/*eslint-disable*/
import React from "react";
// react plugin for creating date-time-picker
import Datetime from "react-datetime";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Close from "@material-ui/icons/Close";
import Notifications from "@material-ui/icons/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Assignment from "@material-ui/icons/Assignment";
import Mail from "@material-ui/icons/Mail";
import Face from "@material-ui/icons/Face";
import Pool from "@material-ui/icons/Pool";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import ListIcon from "@material-ui/icons/List"
import BeachAccess from "@material-ui/icons/BeachAccess"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import blankAvatar from "assets/img/team/blank.jpg"

const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Modal(props) {
    const [signupModal, setSignupModal] = React.useState(false);
    const classes = useStyles();
    return (
    <div className={classes.section}>
        <div className={classes.container}>
            <GridContainer>
                <GridItem xs={12}>
                    <Button block round color="info" onClick={() => setSignupModal(true)}>
                        <Assignment /> {props.buttonTitle}
                    </Button>
                    <Dialog
                        classes={{ root: classes.modalRoot, paper: classes.modal + " " + classes.modalSignup }}
                        open={signupModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setSignupModal(false)}
                        aria-labelledby="signup-modal-slide-title"
                        aria-describedby="signup-modal-slide-description">
                        <Card plain className={classes.modalSignupCard}>
                        <DialogTitle id="signup-modal-slide-title" disableTypography className={classes.modalHeader}>
                            <Button simple className={classes.modalCloseButton} key="close" aria-label="Close" onClick={() => setSignupModal(false)}>
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                        </DialogTitle>
                        <DialogContent id="signup-modal-slide-description" className={classes.modalBody}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.cardHeaderImage}>
                                        <img src={props.img} alt={`Utah Swim Academy - ${props.title} - ${props.name}`} />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.moreInfoBox}>
                                        <h2 className={classes.name}>{props.name}</h2>
                                        <h3 className={classes.title}>{props.title}</h3>
                                        <a href={`mailTo:${props.email}`} target="_blank" className={classes.emailLink}>{props.email}</a>
                                        {!props.admin && <p style={{ marginTop: 15 }} className={classes.descriptionSub}><span className={classes.description}>Text Message Only: </span> <a href={`tel:${props.phone}`} target="_blank" className={classes.emailLink}> {props.phone}</a></p>}
                                        {!props.admin && <p className={classes.descriptionSub}><span className={classes.description}>Primary Personality Type:</span> #{props.primary}</p>}
                                        {!props.admin && <p className={classes.descriptionSub}><span className={classes.description}>Secondary Personality Type:</span> #{props.secondary}</p>}
                                        {!props.admin && <p className={classes.descriptionSub}><span className={classes.description}>Started with USA:</span> {props.started}</p>}
                                        {!props.admin && <p className={classes.descriptionSub}><span className={classes.description}>Speaks:</span> {props.language}</p>}
                                        {!props.admin && <p className={classes.descriptionSub}><span className={classes.description}>Works Well With:</span> {props.work}</p>}
                                        {props.admin && <p className={classes.descriptionSub}>{props.work}</p>}
                                        {props.admin && <p className={classes.descriptionSub}>{props.experience}</p>}
                                    </div>
                                </GridItem>
                                {!props.admin &&
                                    <GridItem xs={12} sm={12} md={12}>
                                        <InfoArea
                                            className={classes.infoArea}
                                            title={"Swim Experience"}
                                            description={<p>{props.experience}</p>}
                                            icon={Pool}
                                            iconColor="info" />
                                        <InfoArea
                                            className={classes.infoArea}
                                            title={"Teaching Goals"}
                                            description={<p>{props.goals}</p>}
                                            icon={ListIcon}
                                            iconColor="primary" />
                                        <InfoArea
                                            className={classes.infoArea}
                                            title={"Love About Teaching"}
                                            description={<p>{props.hobbies}</p>}
                                            icon={BeachAccess}
                                            iconColor="warning" />
                                    </GridItem> 
                                }                               
                            </GridContainer>
                        </DialogContent>
                        </Card>
                    </Dialog>
                </GridItem>
            </GridContainer>
        </div>
    </div>
    );
}

Modal.defaultProps = {
    buttonTitle: "View More Info",
    img: blankAvatar,
    name: "Swim Instructor",
    title: "Instructor",
    primary: "0",
    secondary: "0",
    started: "Date Unknown",
    experience: "None",
    goals: "None",
    hobbies: "None",
    email: "Unknown"
  };